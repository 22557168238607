<template>
	<div id="couponEdit">
		<page-coupon-edit :is-shop="1"></page-coupon-edit>
	</div>
</template>

<script>
	import PageCouponEdit from '@/components/layout/coupon/page-coupon-edit.vue'
	export default{		
		components:{
			PageCouponEdit
		}
	}
</script>

<style>

</style>
